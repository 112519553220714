import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,10,4,5,6,7,9];

export const dictionary = {
		"/(main)": [11,[2],[3]],
		"/adm": [41,[10]],
		"/adm/pictures": [42,[10]],
		"/adm/pictures/[pic_id]": [~43,[10]],
		"/(main)/characters": [12,[2],[3]],
		"/(main)/characters/[character_id]": [~13,[2,4],[3]],
		"/(main)/characters/[character_id]/drawings": [14,[2,4],[3]],
		"/(main)/characters/[character_id]/fursuits": [15,[2,4],[3]],
		"/(main)/characters/[character_id]/photos": [16,[2,4],[3]],
		"/(main)/drawings": [17,[2],[3]],
		"/(main)/fursuits": [18,[2],[3]],
		"/(main)/fursuits/create": [21,[2],[3]],
		"/(main)/fursuits/[fursuit_id]": [~19,[2,5],[3]],
		"/(main)/fursuits/[fursuit_id]/photos": [20,[2,5],[3]],
		"/(main)/makers": [22,[2],[3]],
		"/(main)/makers/[maker_id]": [~23,[2,6],[3]],
		"/(main)/makers/[maker_id]/fursuits": [24,[2,6],[3]],
		"/(main)/notifications": [25,[2],[3]],
		"/(main)/oauth/callback": [26,[2],[3]],
		"/(main)/oauth/success": [27,[2],[3]],
		"/(main)/photos": [28,[2],[3]],
		"/(main)/pictures/[pic_id]": [~29,[2,7],[3]],
		"/(main)/pictures/[pic_id]/edit": [30,[2,7],[3]],
		"/(main)/post": [31,[2],[3]],
		"/(main)/settings": [~32,[2,8],[3]],
		"/(main)/settings/layout": [33,[2,8],[3]],
		"/(main)/settings/theme": [34,[2,8],[3]],
		"/(main)/users": [35,[2],[3]],
		"/(main)/users/[user_id]": [~36,[2,9],[3]],
		"/(main)/users/[user_id]/characters": [37,[2,9],[3]],
		"/(main)/users/[user_id]/drawings": [38,[2,9],[3]],
		"/(main)/users/[user_id]/liked": [39,[2,9],[3]],
		"/(main)/users/[user_id]/photos": [40,[2,9],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';